/**
 * @file bootstrap.ts 启动入口文件
 * @author wangyuzhen01@baidu.com
 */
import {isOnline} from './src/utils/util';
import versionConfig from './version.config';

// SDK版本控制
const {qaVersion, onlineVersion} = versionConfig;

// 判断加载线上版本还是沙盒版本
const version = isOnline() ? onlineVersion : qaVersion;

function boot() {
    const targetUrl = `https://bce.bdstatic.com/lib/@baiducloud/fe-qifu-common-sdk/${version}/qifu-common.sdk.js`;
    const scriptNode = document.createElement('script');
    scriptNode.src = targetUrl;
    scriptNode.defer = true;
    document.body.appendChild(scriptNode);
}

// 存在版本号时再执行启动函数
if (version) {
    boot();
}
