/**
 * @description sdk 沙盒和线上的版本都在此处进行控制。部署沙盒和发布上线时，将流水线中最新构建的版本号在此处进行修改，再次评审通过，跑完流水线，即完成部署沙盒和上线流程。
 * @file version.config.ts 版本控制文件
 * @author wangyuzhen01@baidu.com
*/
interface VersionConfig {
    qaVersion: string;
    onlineVersion: string;
}

const versionConfig: VersionConfig = {
    qaVersion: '1.0.181.1',
    onlineVersion: '1.0.181.1'
};

export default versionConfig;
