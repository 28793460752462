/**
 * @file src/utils/util.ts 工具函数
 * @author wangyuzhen01@baidu.com
 */
import {globalConfig} from '../common/config';
import {ParamObj} from '../interface/common'

// 在指定元素的后面插入一个元素
export function insertAfter(newElement: HTMLElement, referElement: HTMLElement): boolean {
    try {
        referElement.parentNode.insertBefore(newElement, referElement.nextSibling);
        return true;
    } catch {
        return false;
    }
}

// 在指定元素的前面插入一个元素
export function insertBefore(newElement: HTMLElement, referElement: HTMLElement): boolean {
    try {
        referElement.parentNode.insertBefore(newElement, referElement);
        return true;
    } catch {
        return false;
    }
}

// 判断是否是移动端
export function isMobile(): boolean {
    const userAgent = window.navigator.userAgent;
    const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'iPad'];
    for (let i = 0; i < agents.length; i++) {
        if (userAgent.indexOf(agents[i]) > -1) return true;
    }
    return false;
}

// 获取界面类型
export function getPageType(): string {
    return globalConfig.getPageType();
}

// 判断是否是线上地址
export function isOnline(): boolean {
    return !/(localhost)|(qifu-sandbox)|(market-sandbox)|(cloudtest)|(baidu-int)|(qasandbox)|(bcetest)/.test(window.location.origin);
}

// 判断是否是商标交易地址
export function isTrademark(): boolean {
    return /tms\.((baidu)|(baidu-int))\.com/.test(window.location.origin);
}

// 获取公共 Header 渲染进的 节点
export function getRenderNode(): HTMLElement | null {
    let node: HTMLElement;

    const nodeAttrName = 'qifu-common-header_of_sdk';
    // 寻找 id 或类名 符合条件的 dom 节点
    node = document.getElementById(nodeAttrName) || document.querySelector(`.${nodeAttrName}`);
    if (node) {
        return node;
    }

    // 如果 id 和 类名都未找到对应的 dom 节点，则创造相关的 dom 节点
    node = document.createElement('div');
    node.setAttribute('id', nodeAttrName);

    // 依次查询界面中的主内容节点
    const ids = ['root', 'main', 'app', 'product-content'];
    for (let i = 0; i < ids.length; i++) {
        const pageNode = document.getElementById(ids[i]);
        if (pageNode) {
            insertBefore(node, pageNode);
            return node;
        }
    }

    // 插都body下第一个元素的前面
    const rootDom: any = document.body.children[0];
    if (rootDom) {
        insertBefore(node, rootDom);
        return node;
    }
    return null;
}

// 将参数数组转化为对象
export function convertParamArrToObj(arr: Array<string>, obj: ParamObj): ParamObj {
    for (let i = 0; i < arr.length; i++) {
        const tempArr = arr[i].split('=');
        obj[tempArr[0]] = tempArr[1];
    }
    return obj;
}

// 从 url 中获取 query 参数
export function getTrackValue(): string | null {
    const trackKeys: Array<string> = ['trace', 'track', 'pageResource', 'pageSource'];
    let obj: ParamObj = {};

    // 从 search 中获取参数
    const search = window.location.search.substring(1);
    const searchParamArr = search.split('&');
    obj = convertParamArrToObj(searchParamArr, obj);

    // 从 hash 中获取参数
    const hashQueryIndex = window.location.hash.indexOf('?');
    const hashQuery = window.location.hash.substring(hashQueryIndex + 1);
    const hashParamArr = hashQuery.split('&');
    obj = convertParamArrToObj(hashParamArr, obj);

    for (let i = 0; i < trackKeys.length; i++) {
        const key = trackKeys[i];
        if (obj[key]) {
            return decodeURIComponent(obj[key]);
        }
    }

    return null;
}

// 登录链接
export function loginLink() {
    return isOnline()
        ? 'https://login.bce.baidu.com/'
        : 'https://login.bcetest.baidu.com/';
}

// 跳转登录redirect当前地址
export function cacheCurrUrl() {
    return location.origin
        + location.pathname
        + encodeURIComponent(location.search)
        + encodeURIComponent(location.hash);
}

export function isBrowser() {
    return (typeof window !== 'undefined' || typeof document !== 'undefined');
}

export function getCookie(name, cookieText = '') {
    if (isBrowser()) {
        cookieText = document.cookie;
    }

    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    const arr = cookieText.match(reg);

    let ret = '';

    if (arr) {
        ret = decodeURIComponent(arr[2]);
    }

    return ret;
}

export function setCookie(name, value, options) {
    if (typeof options.expires === 'number') {
        let days = options.expires;
        let expiresTime = options.expires = new Date();
        expiresTime.setDate(expiresTime.getDate() + days);
    }

    return (document.cookie = [
        name,
        '=',
        options.notEncodeValue ? value : encodeURIComponent(value),
        options.expires ? '; expires=' + options.expires.toUTCString() : '',
        options.path ? '; path=' + options.path : '',
        options.domain ? '; domain=' + options.domain : '',
        options.secure ? '; secure' : ''
    ].join(''));
}

export function removeCookie(name, options) {
    if (getCookie(name) !== undefined) {
        setCookie(name, '', {
            ...options,
            expires: -1
        });
        return true;
    }

    return false;
}

export function jsonp(url, params, callback, callbackname = 'callback', fix) {
    let idStr = `${url}&${params}`;
    let fun = '';

    if (document.getElementById(url)) {
        document.body.removeChild(document.getElementById(url));
    }

    if (!fix) {
        // 添加时间戳
        url = url + ((url.indexOf('?') === -1) ? '?' : '&') + '_t=' + Math.random();
        // 添加回调
        if (typeof callback === 'function') {
            fun = 'fun_' + new Date().getUTCMilliseconds() + ('' + Math.random()).substring(3);
            window[fun] = '';
            /* eslint-disable */
            eval(fun + '=function(res){callback(res)}');
            /* eslint-enable */
        }
    }
    else {
        url = url + ((url.indexOf('?') === -1) ? '?' : '&') + '_t=fixed';
        if (typeof callback === 'string') {
            fun = callback;
        }
    }
    url = url + '&' + callbackname + '=' + fun;
    url = url + '&' + params;

    const headDom = document.getElementsByTagName('head')[0];
    const oldScript = document.getElementById(idStr);

    if (oldScript) {
        headDom.removeChild(oldScript);
    }

    let scriptDom: any = document.createElement('script');
    scriptDom.src = url;
    scriptDom.id = idStr;
    scriptDom.type = 'text/javascript';
    scriptDom.language = 'javascript';
    headDom.appendChild(scriptDom);
}


export function getUserInfo() {
    let targetMod = isOnline() ? 'https://bce.baidu.com' : 'https://bcetest.baidu.com';

    return new Promise(resolve => {
        jsonp(`${targetMod}/api/account/v2/displayName`, '', (data={result: {cookies: {}, accountId: '', displayName: ''}}) => {
            const host = window.location.hostname;

            const {accountId = '', displayName = ''} = data.result;
            // 需要同步的cookies值
            let syncCookies = {};
            if (accountId && data.result.cookies) {
                syncCookies = {
                    accountId,
                    displayName,
                    ...data.result.cookies
                }
            }

            /* eslint-disable */
            for (let key in syncCookies) {
                /* eslint-enable */
                if (!syncCookies.hasOwnProperty(key)) {
                    continue;
                }
                // 过一段儿时间，同步 tracking cookie 的逻辑就可以去掉了
                // 这里主要是保证老用户的 tracking cookie 从 bce 同步 cloud
                const isTracking = (key === 'CAMPAIGN_TRACK' || key === 'CAMPAIGN_TRACK_TIME');
                // 这三个字段用来鉴权的，转义了就无法鉴权了
                const isNotEncode = (
                    key === 'bce-session'
                    || key === 'bce-user-info'
                    || key === 'bce-ctl-client-cookies'
                );
                if (isTracking && getCookie(key)) {
                    // 如果已经有了，就不需要覆盖了
                    continue;
                }
                const value = syncCookies[key];
                if (value) {
                    if (isTracking) {
                        setCookie(key, value, {expires: 90, path: '/', domain: `.${host}`});
                    }
                    else {
                        setCookie(key, value, {path: '/', domain: `.${host}`, notEncodeValue: isNotEncode});
                    }
                }
                else if (!isTracking) {
                    removeCookie(key, {path: '/', domain: `.${host}`});
                }
            }
            resolve(data.result);
        }, 'callback', null);
    });
}

// 获取实名状态
export function getSealStatus(url) {
    return new Promise(resolve => {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send(null);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 200) {
                resolve(xhr.responseText);
            }
        }
    });
}
// 处理sandbox/online域名
export function hostMap(key: string) {
    const online = {
        console: 'console.bce.baidu.com',
        cloud: 'cloud.baidu.com',
        qifu: 'qifu.baidu.com',
        market: 'market.baidu.com',
        login: 'login.bce.baidu.com'
    };
    const sandbox = {
        console: 'qasandbox.bcetest.baidu.com',
        cloud: 'cloudtest.baidu.com',
        qifu: 'qifu-sandbox.baidu-int.com',
        market: 'market.baidu-int.com',
        login: 'login.bcetest.baidu.com'
    };
    return `https://${isOnline() ? online[key] : sandbox[key]}`;
}
// 获取url参数
export const getUrlParam = (name: string) => {
    let result = '';
    let reg = new RegExp(`[\?\&]${name}=([^\&|\#]*)(\&|\#?)`);
    let matches = window.location.href.match(reg);

    if (matches) {
        result = decodeURIComponent(matches[1]);
    }

    return result;
};

// 去除云header
export const removeCloudHeader = (className: string = '.header-cloud') => {

    let observer = new MutationObserver(() => {
        let cloudHeader = document.querySelector(className);

        if (cloudHeader) {

            let cloudHeaderParent = cloudHeader.parentElement;
            cloudHeaderParent.removeChild(cloudHeader);

            observer.disconnect();
        }
    });
    observer.observe(document.body, {
        childList: true
    });

    let interval = setInterval(() => {

        let cloudHeader = document.querySelector(className);
        if (cloudHeader) {
            let cloudHeaderParent = cloudHeader.parentElement;
            cloudHeaderParent.removeChild(cloudHeader);
            clearInterval(interval);
        }

        setTimeout(() => {clearInterval(interval)}, 6000);
    }, 5);
}