import {
    ConfigDetail,
    GlobalConfig,
    Datasource,
    UserCenterConfig,
    ManageConfigItem
} from '../interface/common'
import {
    getTrackValue,
    hostMap,
    isTrademark
} from '../utils/util';
// 生成对应online/sandbox的host
export const qifuHost = hostMap('qifu');
export const cloudHost = hostMap('cloud');
export const consoleHost = hostMap('console');
export const marketHost = hostMap('market');
export const loginHost = hostMap('login');
export const tms2qifuHost = isTrademark() ? qifuHost : '';

export const configDetail: ConfigDetail = {
    menuItems: [
        {
            label: '首页',
            targetUrl: qifuHost,
            pageType: 'QIFU_PORTAL',
        },
        {
            label: '域名服务',
            pageType: 'BCD_PORTAL',
            childrenItems: [
                {
                    label: '域名注册',
                    targetUrl: `${tms2qifuHost}/product/bcd.html`,
                    pageType: 'BCD_PORTAL'
                },
                {
                    label: '域名交易',
                    targetUrl: `${tms2qifuHost}/product/bcd/land/#/fixed`,
                    pageType: 'BCD_FIXED'
                },
                {
                    label: '委托购买',
                    targetUrl: `${tms2qifuHost}/product/bcd/land/#/domain`,
                    pageType: 'BCD_ENTRUST'
                },
                {
                    label: '企信直达号',
                    targetUrl: `${cloudHost}/product/BCD/qixin01.html?track=BCD_PORTAL01_qixin&pageSource=BCD_PORTAL01_qixin`,
                    pageType: 'BCD_PORTAL01',
                    tagImg: 'https://bcd-public.cdn.bcebos.com/bcd_portal/20221109/brage-1.png'
                },
                {
                    label: '备案管家',
                    targetUrl: `${tms2qifuHost}/product/bcd/beian/icp`,
                    pageType: 'BCD_BEIAN'
                }
            ]
        },
        {
            label: '建站服务',
            pageType: 'AIPAGE_PORTAL',
            childrenItems: [
                {
                    label: '智能建站',
                    targetUrl: `${cloudHost}/product/aipage.html`,
                    pageType: 'AIPAGE_PORTAL'
                },
                {
                    label: '小程序开发',
                    targetUrl: `${cloudHost}/product/aipage.html`,
                    pageType: 'AIPAGE_PORTAL'
                },
                {
                    label: 'SSL证书',
                    targetUrl: `${cloudHost}/product/ssl.html`,
                    pageType: 'CAS_PORTAL'
                },
            ]
        },
        {
            label: '工商财税',
            pageType: 'CBS_PORTAL',
            childrenItems: [
                {
                    label: '公司注册',
                    targetUrl: `${tms2qifuHost}/product/crs`,
                    pageType: 'CRS_PORTAL'
                },
                {
                    label: '代理记账',
                    targetUrl: `${tms2qifuHost}/product/bookkeeping`,
                    pageType: 'BKP_PORTAL'
                },
                {
                    label: '经营相关',
                    targetUrl: `${tms2qifuHost}/product/cbs`,
                    pageType: 'CBS_PORTAL'
                }
            ]
        },
        {
            label: '商标知产',
            pageType: 'TMS_PORTAL',
            childrenItems: [
                {
                    label: '商标注册',
                    targetUrl: `${tms2qifuHost}/product/tms`,
                    pageType: 'TMS_SEARCH_PORTAL'
                },
                {
                    label: '商标交易',
                    targetUrl: `${tms2qifuHost}/product/tms/transaction`,
                    pageType: 'TMS_TRANSACTION'
                },
                {
                    label: '著作权服务',
                    targetUrl: 'https://cloud.baidu.com/product/ruanzhu.html',
                    pageType: 'WORK_PORTAL'
                },
                {
                    label: '专利服务',
                    targetUrl: `${marketHost}/product/detail/68b3fb04-1d09-4427-bda4-f3ce14d26d59`,
                    pageType: 'PATENT_PORTAL'
                },
            ]
        },
        {
            label: '资质备案',
            pageType: 'BLA_PORTAL',
            childrenItems: [
                {
                    label: '企业认定',
                    targetUrl: `${tms2qifuHost}/product/bla?trace=qifuheader&pageResource=qifuheader&select=5`,
                    pageType: 'ENTERPRISE_AUTHENT_PORTAL'
                },
                {
                    label: '资质许可',
                    targetUrl: `${tms2qifuHost}/product/bla`,
                    pageType: 'BLA_PORTAL'
                },
                {
                    label: '体系认证',
                    targetUrl: `${tms2qifuHost}/product/bla?trace=qifuheader&pageResource=qifuheader&select=5`,
                    pageType: 'SYSTEM_AUTHENT_PORTAL'
                },
                {
                    label: '备案服务',
                    targetUrl: `${marketHost}/product/detail/9820aa5c-3f03-47c3-8ccc-ac63a85b84b0`,
                    pageType: 'KEEP_RECORD_PORTAL'
                },
            ]
        },
        {
            label: '低代码平台',
            targetUrl: `${cloudHost}/product/isuda.html`,
            pageType: 'ISUDA_PORTAL'
        },
        {
            label: '企业应用',
            pageType: 'EXTEND_PORTAL',
            childrenItems: [
                {
                    label: '企业邮箱',
                    targetUrl: `${marketHost}/product/detail/fd34920e-0e7b-4e95-a065-b51f4935c6ad`,
                    pageType: 'ENTERPRISE_MAIL_PORTAL'
                },
                {
                    label: '搜索推广',
                    targetUrl: `${marketHost}/product/detail/c2e1c4a1-37cd-46a8-8026-54ef124bc6e2`,
                    pageType: 'SEARCH_EXTEND_PORTAL'
                },
                {
                    label: '短视频推广',
                    targetUrl: `${marketHost}/product/detail/add04065-7397-46be-a470-0266bf96006e`,
                    pageType: 'SHORT_ViDEO_PORTAL'
                }
            ]
        },
        {
            label: '渠道合作',
            targetUrl: 'https://huoban.baidu.com',
            pageType: 'JZ_PORTAL'
        }
    ]
};

// 全局配置
export const globalConfig: GlobalConfig = {
    datasource: [
        {
            pageType: 'CRS_PORTAL',
            pageTypeDesc: '工商注册官网',
            link: 'https://cloud.baidu.com/product/cbs',
            onlineLinkRegExp: /(market|cloud)\.baidu\.com\/product\/cbs/,
            sandboxLinkRegExp: /((market-sandbox\.baidu)|(market\.baidu-int)|(cloudtest\.baidu))\.com\/product\/cbs/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'TMS_PORTAL',
            pageTypeDesc: '商标注册官网',
            link: 'https://cloud.baidu.com/product/tms',
            onlineLinkRegExp: /(cloud\.baidu\.com\/product\/tms)|(market\.baidu\.com\/product\/tms)/,
            sandboxLinkRegExp: /(cloudtest\.baidu\.com\/product\/tms)|(market\.baidu-int\.com\/product\/tms)/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'TMS_TRANSACTION',
            pageTypeDesc: '商标交易官网',
            link: 'https://cloud.baidu.com/product/tms/transaction',
            onlineLinkRegExp: /(cloud\.baidu\.com\/product\/tms\/transaction)|(market\.baidu\.com\/product\/tms\/transaction)|(tms\.baidu\.com)/,
            sandboxLinkRegExp: /(cloudtest\.baidu\.com\/product\/tms\/transaction)|(market\.baidu-int\.com\/product\/tms\/transaction)|(tms\.baidu-int\.com)/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'BLA_PORTAL',
            pageTypeDesc: '资质代办官网',
            link: 'https://cloud.baidu.com/product/bla',
            onlineLinkRegExp: /(market|cloud)\.baidu\.com\/product\/bla/,
            sandboxLinkRegExp: /((cloudtest\.baidu)|(market\.baidu-int))\.com\/product\/bla/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'BKP_PORTAL',
            pageTypeDesc: '代理记账portal界面',
            link: 'https://cloud.baidu.com/product/bookkeeping',
            onlineLinkRegExp: /(market|cloud)\.baidu\.com\/product\/bookkeeping/,
            sandboxLinkRegExp: /((market-sandbox\.baidu)|(market\.baidu-int)|(cloudtest\.baidu))\.com\/product\/bookkeeping/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'CRS_DETAIL',
            pageTypeDesc: '商标注册详情页',
            link: 'https://cloud.baidu.com/product/crs',
            onlineLinkRegExp: /(market|cloud)\.baidu\.com\/product\/crs/,
            sandboxLinkRegExp: /((market-sandbox\.baidu)|(market\.baidu-int)|(cloudtest\.baidu))\.com\/product\/crs/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'BCD_PORTAL',
            pageTypeDesc: '域名官网',
            link: 'https://cloud.baidu.com/product/bcd.html',
            onlineLinkRegExp: /cloud\.baidu\.com\/product\/bcd(?!\/cas)/,
            sandboxLinkRegExp: /cloudtest\.baidu\.com\/product\/bcd(?!\/cas)/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'CAS_PORTAL',
            pageTypeDesc: 'SSL证书官网',
            link: 'https://cloud.baidu.com/product/ssl.html',
            onlineLinkRegExp: /cloud\.baidu\.com\/product\/ssl/,
            sandboxLinkRegExp: /cloudtest\.baidu\.com\/product\/ssl/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'AIPAGE_PORTAL',
            pageTypeDesc: 'AIPAGE官网',
            link: 'https://aipage.baidu.com',
            onlineLinkRegExp: /aipage\.baidu\.com/,
            sandboxLinkRegExp: /aipage\.baidu\.com/, // aipage 没有沙盒访问地址，直接访问线上
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'API_PORTAL',
            pageTypeDesc: 'API商城',
            link: 'https://apis.baidu.com',
            onlineLinkRegExp: /apis\.baidu\.com/,
            sandboxLinkRegExp: /apis-sandbox\.baidu-int\.com/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'QIFU_618_ACTIVITY_PORTAL',
            pageTypeDesc: '企业服务中心618活动界面',
            link: 'https://qifu.baidu.com/618/activity',
            onlineLinkRegExp: /qifu\.baidu\.com\/618\/activity/,
            sandboxLinkRegExp: /qifu-sandbox\.baidu(-int)?\.com\/618\/activity/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'QIFU_ACTIVITY_PORTAL',
            pageTypeDesc: '企业服务中心活动界面',
            link: 'https://qifu.baidu.com/activity',
            onlineLinkRegExp: /qifu\.baidu\.com\/activity/,
            sandboxLinkRegExp: /qifu-sandbox\.baidu(-int)?\.com\/activity/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'QIFU_PORTAL',
            pageTypeDesc: '企业服务中心',
            link: 'https://qifu.baidu.com',
            onlineLinkRegExp: /qifu\.baidu\.com/,
            sandboxLinkRegExp: /qifu-sandbox\.baidu(-int)?\.com/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'ISUDA_PORTAL',
            pageTypeDesc: '低代码开发',
            link: 'https://cloud.baidu.com/product/isuda.html',
            onlineLinkRegExp: /cloud\.baidu\.com\/product\/isuda/,
            sandboxLinkRegExp: /cloudtest\.baidu\.com\/product\/isuda/,
            configDetail: {
                ...configDetail,
            }
        },
        {
            pageType: 'LOCALHOST',
            pageTypeDesc: '本地开发',
            link: 'https://qifu.baidu.com',
            onlineLinkRegExp: /^www.baidu.com$/,
            sandboxLinkRegExp: /(localhost)|(pagemaker)|(\d+\.\d+\.\d+\.\d+)|(:\d+)/,
            configDetail: {
                ...configDetail,
            }
        }
    ],
    pageTypeIndexMap: {},
    getIndexByPageType: function (pageType) {
        if (/\d+/.test(this.pageTypeIndexMap[pageType])) {
            return this.pageTypeIndexMap[pageType];
        }
        const index = this.datasource.findIndex((item: Datasource) => {
            return item.pageType === pageType;
        });
        if (index > -1) {
            this.pageTypeIndexMap[pageType] = index;
        }
        return index;
    },
    isOnline: function () {
        const href = window.location.href;
        return this.datasource.some((item: Datasource) => {
            return item.onlineLinkRegExp.test(href);
        });
    },
    getPageType: function () {
        const href = window.location.href;
        const index = this.datasource.findIndex((item: Datasource) => {
            return item.onlineLinkRegExp.test(href) || item.sandboxLinkRegExp.test(href);
        });
        return index > -1 ? this.datasource[index].pageType : 'DEFAULT';
    },
    getConfigDetailByPageType: function (pageType) {
        const index = this.getIndexByPageType(pageType);
        return index > -1 ? this.datasource[index].configDetail : configDetail;
    }
};

// 登录状态用户中心
export const userCenterConfig: UserCenterConfig = {
    funcList: [
        {
            label: '用户中心',
            openNew: true,
            link: `${consoleHost}/iam/#/iam/baseinfo~${getTrackValue() || globalConfig.getPageType() + '_QifuHeader'}`
        },
        {
            label: '财务及订单',
            openNew: true,
            link: `${consoleHost}/billing/#/account/index~${getTrackValue() || globalConfig.getPageType() + '_QifuHeader'}`
        },
        {
            label: '消息中心',
            openNew: true,
            link: `${consoleHost}/mc/#/mc/letterlist~${getTrackValue() || globalConfig.getPageType() + '_QifuHeader'}`
        },
        {
            label: '我的工单',
            openNew: true,
            link: `${consoleHost}/mc/#/mc/letterlist~${getTrackValue() || globalConfig.getPageType() + '_QifuHeader'}`
        },
        {
            label: '退出登录',
            openNew: false,
            link: `${loginHost}/logout`
        }
    ]
};

// 头部功能管理
export const funcManageConfig: Array<ManageConfigItem> = [
    {
        label: '智能云首页',
        link: `${cloudHost}`
    },
    {
        label: '备案',
        link: 'https://cloud.baidu.com/beian/index.html'
    },
    {
        label: '文档',
        link: 'https://cloud.baidu.com/doc/index.html'
    },
    {
        label: '管理控制台',
        link: `${consoleHost}`
    },
];
